import { ReactNode } from "react";

import { InputSearchWithPortOption } from "../types";

import { PortInfoType } from "..";
import Styled from "./index.styles";

interface PortOptionItemProps<T> extends InputSearchWithPortOption<T> {
  searchTerm: string;
  onSelect: (portInfo: PortInfoType) => void;
  highlightMatch: (label: string, searchTerm: string) => ReactNode;
}

export default function PortOptionItem<T>({
  label,
  iconInfo,
  portInfo,
  searchTerm,
  onSelect,
  highlightMatch,
}: PortOptionItemProps<T>) {
  return (
    <Styled.portOptionItem key={label} onClick={() => onSelect(portInfo)}>
      {iconInfo && <iconInfo.Icon />}

      <p>{highlightMatch(label, searchTerm)}</p>
    </Styled.portOptionItem>
  );
}
