import { ReactNode } from "react";

import { InputSearchWithCategoryPortOption } from "../types";

import { PortInfoType } from "..";
import Styled from "./index.styles";

interface PortOptionWithCategoryItemProps<T>
  extends InputSearchWithCategoryPortOption<T> {
  searchTerm: string;
  onSelect: (portInfo: PortInfoType) => void;
  highlightMatch: (label: string, searchTerm: string) => ReactNode;
}

export default function PortOptionWithCategoryItem<T>({
  category,
  optionList,
  searchTerm,
  onSelect,
  highlightMatch,
}: PortOptionWithCategoryItemProps<T>) {
  if (!optionList.length) return null;

  const { label: categoryLabel } = category;

  return (
    <Styled.categoryPortOptionItem key={categoryLabel}>
      <Styled.category>
        <span className="label">{categoryLabel}</span>
        <p className="desc">{category.desc}</p>
      </Styled.category>

      <ul className="port-option-list">
        {optionList.map(({ label: optionLabel, iconInfo, portInfo }) => (
          <Styled.portOptionItem
            key={optionLabel}
            onClick={() => onSelect(portInfo)}
          >
            {iconInfo && <iconInfo.Icon />}

            <p>{highlightMatch(optionLabel, searchTerm)}</p>
          </Styled.portOptionItem>
        ))}
      </ul>
    </Styled.categoryPortOptionItem>
  );
}
